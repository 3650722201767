
.no-tailwind h1,
.no-tailwind h2,
.no-tailwind h3,
.no-tailwind h4,
.no-tailwind h5,
.no-tailwind h6 {
    font-weight: inherit;
}

.no-tailwind ul,
.no-tailwind ol{
    all: revert ;
    white-space: pre-wrap;
}

.no-tailwind img{
    /*display: inline-block;*/
    width: 100%
}